<ng-container *appIfIsMobile>
  <router-outlet></router-outlet>
</ng-container>

<ng-container *appIfIsDesktop>
  <div class="layout-wrapper layout-2 position-relative">
    <div class="layout-inner d-flex" [ngClass]="{'backdrop-filter-layout': isPrivacy || isTerms}">
      <app-layout-sidenav-left
        *ngIf="!isRestaurantModalOpen && !isPostModalOpen && !isFavoriteModalOpen && !isVideoModalOpen && !isExplorePage && !isUserPage && !isRestaurantPage"
        class="position-fixed"
      ></app-layout-sidenav-left>

      <div
        *ngIf="!isSearchModalOpen && !isFavoriteModalOpen && !isSubscribeModalOpen && isSidenavLeftOrRightMouseOver && !isPostModalOpen && !isVideoModalOpen && !isExplorePage && !isUserPage"
        class="position-fixed logo-div"
      >
        <i class="icon-v2 mmmm-2x-red left-side"></i>
      </div>

      <div appAddClassIfDesktop="layout-container-desktop" class="layout-container w-100 flex-fill">
        <router-outlet></router-outlet>

        <div
          *ngIf="isSubscribeModalOpen || isSearchModalOpen || isFavoriteModalOpen || isVideoModalOpen"
          class="outside-mmmm-logo"
          (click)="closeModal()"
        >
          <span
            class="icon-v2 smaller"
            (mouseover)="outsideMMMMLogo = true"
            (mouseleave)="outsideMMMMLogo = false"
            [ngClass]="{'mmmm-2x-gray scale-07': !outsideMMMMLogo, 'mmmm-2x-red scale-07': outsideMMMMLogo}"
          ></span>
        </div>
      </div>

      <app-layout-sidenav-right
        *ngIf="!isLoginModalOpen && !isRestaurantModalOpen && !isPostModalOpen && !isFavoriteModalOpen && !isVideoModalOpen && !isExplorePage && !isUserPage"
      ></app-layout-sidenav-right>

      <app-layout-left-on-right
        *ngIf="!isLoginModalOpen && !isRestaurantModalOpen && !isPostModalOpen && !isFavoriteModalOpen && !isVideoModalOpen && (isExplorePage || isUserPage)"
        class="position-fixed"
      ></app-layout-left-on-right>
    </div>
  </div>
</ng-container>
