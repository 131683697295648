<div *appIfIsMobile class="top-input-modal safe-area-top">
  <div>
    <i *ngIf="!hiddenPlaceholder" class="icon-v2 search-white"></i>

    <app-search-city-input
      inputClass="top-input-modal-input search-filters-mobile-location-input"
      (inputValue)="searchInputValue($event)"
      (selectPlace)="onSelectPlace($event)"
    ></app-search-city-input>
  </div>

  <div
    *ngIf="!searchValueExists"
    class="search-city-find-me flex-content-vc px-1-25rem safe-area-top"
  >
    <a appHrefNone class="text-white" (click)="onFindMe()">Find me</a>
  </div>
</div>
